<template>
  <div class="contact_page">
    <!-- 头部 -->
      <div class="banner">
      </div>
      <div class="icon_show">
        <el-breadcrumb style="font-size: 16px;" separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/aboutZC' }">关于紫传</el-breadcrumb-item>
          <el-breadcrumb-item :to="{ path: '/aboutZC/contact' }">联系紫传</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="icon_page" >
          <div class="icon_page_single" v-for="items in iconList" :key="items.id">
            <img :src="items.src">
            <div class="icon_page_single_div">
              <span class="icon_page_single_spanO">{{items.title}}</span>
              <span class="icon_page_single_spanT">{{items.info}}</span>
            </div>
          </div>
        </div>
        <div class="input_page">
          <p class="input_page_pO" ref="inputPagePO">感谢您的信任与支持</p>
          <p class="input_page_pT" ref="inputPagePT">如果有需求请填写如下表格提交资料，您将免费获得开发方案及报价</p>
          <div class="input_page_box" ref="inputPageBox">
            <div class="input-group">
              <input required="" class="input_page_single" type="text" name="text" autocomplete="off" v-model="queryParams.name">
              <label class="user-label">您的姓名</label>
            </div>
            <div class="input-group">
              <input required="" class="input_page_single" type="text" name="text" autocomplete="off" v-model="queryParams.phone">
              <label class="user-label">手机号码</label>
            </div>
            <div class="input-group">
              <input required="" class="input_page_single" type="text" name="text" autocomplete="off" v-model="queryParams.companyName">
              <label class="user-label">公司名称</label>
            </div>
          </div>
          <div class="input_page_box" ref="input_page_box2" style="width: 100%">
            <div class="input-group" style="width: 100%; margin-right: 63px;">
              <input required="" class="input_page_single" type="text" name="text" autocomplete="off" v-model="queryParams.telephone" style="width: 99.6%">
              <label class="user-label">您的需求</label>
            </div>
            <!-- <div class="input-group">
              <input required="" class="input_page_single" type="text" name="text" autocomplete="off" v-model="queryParams.email">
              <label class="user-label">公司邮箱</label>
            </div> -->
          </div>
          <div class="input_page_pTh" ref="inputPagePTh">
            <p>免费获得开发方案</p>
          </div>
        </div>
        <!-- 地图 -->
        <div class="map" ref="map">
          <baidu-map class="bm-view" :center="markerPoint" :zoom="19" :dragging="true" :scroll-wheel-zoom="true">
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-map-type>
            <bm-marker :position="position" :dragging="false" animation="BMAP_ANIMATION_BOUNCE">
              <bm-info-window :show="show" style="font-size:13px"></bm-info-window>
            </bm-marker>
          </baidu-map>
        </div>
      </div>
    <!-- 路由出口 -->
  </div>
</template>
<script>
// 图片
import address from '@/assets/img/contact/contact_address.png'
import link from '@/assets/img/contact/contact_link.png'
import mail from '@/assets/img/contact/contact_mail.png'
import phone from '@/assets/img/contact/contact_phone.png'

export default {
  name: 'contactIndex',
  data () {
    return {
      // 地图数据
      markerPoint: { lng: 117.148729, lat: 36.670259 },
      show: false,
      isBig: false,
      values: [],
      position: { lng: 117.148729, lat: 36.670259 },
      // icon图标
      iconList: [
        {
          title: '服务电话为您免费咨询',
          info: '15554150617',
          src: phone,
          id: 1
        },
        {
          title: '公司邮箱',
          info: '1253788613@qq.com',
          src: mail,
          id: 2
        },
        {
          title: '公司地址',
          info: '济南市历城区舜泰广场10号楼11层',
          src: address,
          id: 3
        },
        {
          title: '公司网址',
          info: 'www.purplegend.com',
          src: link,
          id: 4
        }
      ],
      // 查询条件
      queryParams: {
        name: '',
        phone: '',
        companyName: '',
        need: ''
      }
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll () {
      const __currentScroll = window.pageYOffset
      // 此处数值越大，那么触发事件就越早
      if (__currentScroll >= this.$refs.inputPagePO.offsetTop - 850) {
        if (this.$refs.inputPagePO.className === 'input_page_pO') {
          this.$refs.inputPagePO.className = this.$refs.inputPagePO.className + ' animated fadeInUpBig'
          this.$refs.inputPagePT.className = this.$refs.inputPagePT.className + ' animated fadeInDownBig'
          this.$refs.inputPageBox.className = this.$refs.inputPageBox.className + ' animated fadeInDownBig'
          this.$refs.inputPagePTh.className = this.$refs.inputPagePTh.className + ' animated fadeInUpBig'
          this.$refs.input_page_box2.className = this.$refs.input_page_box2.className + ' animated fadeInUpBig'
        }
      }
      if (__currentScroll >= this.$refs.map.offsetTop - 900) {
        if (this.$refs.map.className === 'map') {
          this.$refs.map.className = this.$refs.map.className + ' animated fadeInUpBig'
        }
      }
    }
  }
}
</script>
<style lang="less" scoped>
.contact_page{
  overflow: hidden;
  width: 100%;
  min-width: 1400px;
}
.banner {
    height: 500px;
    background: url('~@/assets/img/contact/inner-banner.jpg') no-repeat center;
    background-size: 100% 100%;
}
.icon_show {
  width: 1400px;
  padding-top: 39px;
  margin: auto;
  p {
    width: 113px;
    height: 16px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #333333;
  }
  .icon_page {
    display: flex;
    justify-content: space-between;
    margin-top: 39px;
    .icon_page_single {
      position: relative;
      width: 335px;
      height: 100px;
      background: #fff;
      img {
        float: left;
        width: 70px;
        height: 70px;
      }
      .icon_page_single_div{
        width: 73%;
        float: right;
        padding: 10px 0;
        .icon_page_single_spanO{
          display: block;
          font-family: MicrosoftYaHei;
          font-size: 14px;
          color: #999999;
          margin-bottom: 5px;
        }
        .icon_page_single_spanT{
          display: block;
          font-family: MicrosoftYaHei;
          font-size: 15px;
          color: #333333;
        }
      }
    }
    .icon_page_single:nth-child(2) {
      width: 360px;
      .icon_page_single_div{
        width: 75%;
      }
    }
    .icon_page_single:nth-child(3) {
      width: 400px;
      .icon_page_single_div{
        width: 77%;
      }
    }
    .icon_page_single:last-child {
      width: 245px;
      .icon_page_single_div{
        width: 62%;
      }
    }
  }
  .input_page {
    height: 435px;
    color: #333333;
    margin: 60px 0;
    overflow: hidden;
    .input_page_box {
      height: 120px;
      display: inline-flex;
      justify-content: space-between;
      .input-group {
        width: 30%;
        height: 80px;
        margin-right: 22px;
        position: relative;
        .input_page_single{
          width: 375px;
          height: 100%;
          color: #333333;
          padding-left: 50px;
          padding-right: 15px;
          font-size: 16px;
          transition: border 150ms cubic-bezier(0.4,0,0.2,1);
        }
        .user-label {
          position: absolute;
          left: 50px;
          top: 12px;
          color: #9e9e9e;
          pointer-events: none;
          transform: translateY(1rem);
          transition: 150ms cubic-bezier(0.4,0,0.2,1);
        }
      }
      .input_page_single:focus, .input_page_single:valid {
        outline: none;
        border: 1.5px solid #1a73e8;
      }

      .input_page_single:focus ~ label, .input_page_single:valid ~ label {
        transform: translateY(-100%) scale(0.8);
        background-color: #fff;
        padding: 0 .2em;
        color: #2196f3;
      }
    }
    .input_page_pO {
      width: 324px;
      margin: 0 auto;
      font-family: MicrosoftYaHei;
      height: 36px;
      font-size: 36px;
      margin-bottom: 20px;
    }
    .input_page_pT {
      width: 480px;
      margin: 0 auto;
      height: 16px;
      font-family: MicrosoftYaHeiLight;
      font-size: 16px;
      margin-bottom: 40px;
    }
    .input_page_pTh {
      margin: 0 auto;
      width: 300px;
      height: 80px;
      background-color: #3179ff;
      border-radius: 4px;
      p {
        width: 192px;
        margin: 0 auto;
        line-height: 80px;
        font-family: MicrosoftYaHei;
        font-size: 24px;
        color: #ffffff;
      }
    }
  }
  .bm-view {
    width: 100%;
    height: 600px;
  }
}
</style>
